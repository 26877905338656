import {inject} from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { JwtService } from '../../../../shared/services/jwtservice';
import { ProfileService } from '../../../../shared/services/profielservice';
import { config } from '../../../../shared/services/config';
import Scrollbar from 'smooth-scrollbar';
import {LoginService} from "../../../../shared/services/loginservice";
import { Router } from 'aurelia-router';

@inject(DialogController, JwtService, ProfileService,Router,LoginService)
export class Viewdocument {
    static inject = [Element];
    isDragging = false;
    dialog;
  pageSize = 10;

   constructor(DialogController, JwtService, ProfileService,router,loginService) {
      this.controller = DialogController;
      this.jwtService = JwtService;
      this.profileService = ProfileService;
       this.router = router;
       this.loginService = loginService;
   }

   async bind() {
      setTimeout(()=>{
        Scrollbar.init(document.querySelector('.viewdocument-scrollbar'));
      },100)
   }

   activate(viewData) {
      this.EmployeeDocumentID = viewData.EmployeeDocumentID
      this.DocumentType = viewData.DocumentTypeText;
      this.DocumentName = viewData.Subject;//DocumentNameDisplay
      this.DocumentNumber = '';
      this.DocIssueDate = viewData.DocIssuedDate;
      this.ExpiryDate = viewData.DocExpiryDate;
      this.IssueAuthority = viewData.IssuingAuthority;
      this.Comments = viewData.Comments;
      this.Files = viewData.Path; //LastUpdatedName
      this.Displayfiles = viewData.MultiplePaths;
   }

   async attached() {

      this.empID = this.jwtService.getEmployeeId();

      const DocumentHistory = await this.profileService.GetDocumentHistory(this.EmployeeDocumentID);
      this.EmployeeDocumentsHistory = DocumentHistory;

      const tempId = 8098;
      const DocumentFieldHistoryData = await this.profileService.GetDocumentFieldHistory(this.empID);
      this.documentFieldHistory = await DocumentFieldHistoryData;
   }

   downloadFile(path) {
    const downloadUrl = config.img_url + path;
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.target = '_blank';
    link.download = downloadUrl;
    link.click();
  }

   cancel() {
      this.controller.cancel();
   }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }
}
