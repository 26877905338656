import {inject,bindingMode,bindable,customAttribute } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import { ProfileService } from '../../../../shared/services/profielservice';
import { JwtService } from '../../../../shared/services/jwtservice';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import { Physicalcopypopup } from '../physicalcopypopup/physicalcopypopup';
import { Confirmdelete } from '../deletefile/confirmdelete';
import { config } from '../../../../shared/services/config';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import Scrollbar from 'smooth-scrollbar';
import {LoginService} from "../../../../shared/services/loginservice";
import { Router } from 'aurelia-router';

@customAttribute('custom-file-input')
@inject(DialogController, ProfileService, JwtService, ValidationControllerFactory, DialogService, Router,LoginService,Element)
export class Editdocument {

   @bindable({
      defaultBindingMode: bindingMode.twoWay
   }) DOIdate;
   @bindable({
      defaultBindingMode: bindingMode.twoWay
   }) Expirydate;
   @bindable({
      defaultBindingMode: bindingMode.twoWay
   }) returndate;

   notifyFields = false;
   showOtherFields = false;
   showPhysicalCopy = false;
   physicalNotifyDays = false;
   isDisabled = true;
   documentTypesDropdown = [];
   countryDropdown = [];
   file;
   uploadedFiles = [];
   combinedFiles = [];
   viewFile = true;
   subfreq = true;
   selectedDocType = null; //aurelia select
   selectedCountry = null;
   selectedState = null;
   selectedNotifyFreq = null;
   selectedNotifyTypes = null;
   URL=config.img_url;
    isDragging = false;
    dialog;
    static inject = [Element];
   constructor(controller, profileService, JwtService, controllerFactory, dialogService, router,loginService,element) {
      this.controller = controller;
      this.profileService = profileService;
      this.JwtService = JwtService;
      this.controllerFactory = controllerFactory.createForCurrentScope();
      this.dialogService = dialogService;
      this.element = element;
       this.router = router;
       this.loginService = loginService;
      this.empID = this.JwtService.getEmployeeId();

      ValidationRules.customRule(
        'validEmails',
        (value, obj) => {
           if (typeof value !== 'string') {
              return false;
           }
           const emails = value.split(',');

           const emailRegex = /^[^\s@]+@[^\s@]+\.(com|co|in|org|cc|net)$/;
           const invalidEmails = [];
           for (const email of emails) {
              const trimmedEmail = email.trim();
              if (!emailRegex.test(trimmedEmail)) {
                 invalidEmails.push(trimmedEmail);
              }
           }
           // Check if any emails are invalid
           if (invalidEmails.length > 0) {
              obj.emailsErrors = ['Invalid email address(es)'];
              return false;
           }
           // Check if emails are separated by commas
           if (emails.length > 1 && value.indexOf(',') === -1) {
              obj.emailsErrors = ['Email(s) should be separated by commas'];
              return false;
           }
           obj.emailsErrors = [];
           return true;
        },
        '${$displayName} is invalid.',
        (obj) => ({
           obj
        })
     );

     ValidationRules.customRule(
       'file',
       () =>{
       if(this.fileValidatorRule === true){
         return false;
       }else{
         return true;
       }
     },
     '${$displayName} is required'
     );

      ValidationRules
         .ensure('selectedDocType').required().withMessage('Document type is required')
         .ensure('Subject').required().withMessage('Document name is required')
         .ensure('DocIssuedDate').required().withMessage('Issue date is required')
         .ensure('NotifyDays').required().withMessage('Notify before expiry day is required.')
         .ensure('upload').required()
         .satisfiesRule('file')
         .ensure('NotifyOtherEmailIDs').required().withMessage('Email id(s) field is required').then()
         .satisfiesRule('validEmails').withMessage("Invalid email address and Email(s) should be separated by commas")
         .ensure('NotifyEmpInd')
            .displayName('Employee')
            .satisfies(() => {
              return this.NotifyEmpInd || this.NotifyHRInd || this.NotifyRMInd || this.NotifyOtherInd;
            })
            .withMessage('Please select at least one notified.')
         .on(this);
   }

   docTypeMatcher = (a, b) => {
      return a.key === b.key;
   }

   countryMatcher = (a, b) => {
      return a.key === b.key;
   }

   stateMatcher = (a, b) => {
      return a.key === b.key;
   }

   selectedNotifyFreqMatcher = (a, b) => {
      return a.key === b.key;
   }

   selectedNotifyTypesMatcher = (a, b) => {
      return a.key === b.key;
   }

   async bind() {
      setTimeout(()=>{
        Scrollbar.init(document.querySelector('.editdocument-scrollbar'));
      },100)
   }

   async activate(documentData) {
      console.log(documentData);
      this.EmployeeDocumentID = documentData.EmployeeDocumentID;
      this.DocumentName = documentData.DocumentName;
      this.DocumentType = documentData.DocumentType;
      this.editFiles = documentData.MultiplePaths;
      this.upload = this.editFiles;
      this.NotifyExpiryDtIND = documentData.NotifyExpiryDtIND;
      if (this.NotifyExpiryDtIND === true) {
         this.notifyFields = true;
      }
      this.NotifyDays = documentData.NotifyDays;
      this.DocIssuedDate = documentData.DocIssuedDate;
      this.DocExpiryDate = documentData.DocExpiryDate;
      this.CountryID = documentData.CountryID;
      this.StateID = documentData.StateID;
      this.IssuingAuthority = documentData.IssuingAuthority;
      this.NotifyEmpInd = documentData.NotifyEmpInd;
      this.NotifyHRInd = documentData.NotifyHRInd;
      this.NotifyRMInd = documentData.NotifyRMInd;
      this.NotifyOtherInd = documentData.NotifyOtherInd;
      if (this.NotifyOtherInd === true) {
         this.showOtherFields = true;
      }
      this.NotifyOtherEmailIDs = documentData.NotifyOtherEmailIDs;
      this.PhysicalCopyIND = documentData.PhysicalCopyIND;
      if (this.PhysicalCopyIND === true) {
         this.showPhysicalCopy = true;
      }
      this.PhysicalCopyComments = documentData.PhysicalCopyComments;
      this.NotificationFrequencyType = documentData.NotificationFrequencyType;
      if(this.NotificationFrequencyType == "1"){
        this.subFreq = false;
      }
      else if(this.NotificationFrequencyType == "2"){
        this.subFreq = true;
      }else{
        this.subFreq = false;
      }
      this.NotificationSubFrequencyType = documentData.NotificationSubFrequencyType;
      this.RetunNotifiIND = documentData.RetunNotifiIND;
      this.ExpectedReturnDate = documentData.ExpectedReturnDate;
      this.ReturnNotifiDays = documentData.ReturnNotifiDays;
      this.Comments = documentData.Comments;
      this.Subject = documentData.Subject;
      await this.selectCountry(this.CountryID);
   }

   mailFreq(data){
    if(data.key == '2'){
      this.subFreq = true;
    }else if(data.key == '1'){
      this.subFreq = false;
    }
   }

   async attached() {
    this.specializationInput.focus();

      if (this.RetunNotifiIND === true) {
         this.physicalNotifyDays = true;
         this.expectedReturnDate();
      }
      flatpickr(this.DOIPicker, {
         closeOnSelect: true,//new Date().toISOString().split("T")[0]
         dateFormat: 'd-m-Y', // the date format (default is 'Y-m-d')
         minDate: null, // the minimum selectable date in the calendar (default is null, which means no limit)
         maxDate: null, // the maximum selectable date in the calendar (default is null, which means no limit)
         defaultDate: this.DOIdate, // the initial date that should be shown in the calendar (default is null, which means today's date)
         enableTime: false, // whether to allow selecting time in addition to date
         enableSeconds: false, // whether to allow selecting seconds in addition to time
         time_24hr: false, // whether to use a 24-hour clock for time input
         minuteIncrement: 1, // the increment for minutes in the time input
         hourIncrement: 1, // the increment for hours in the time input
         timeFormat: 'h:i K', // the format for the time input (default is 'h:i K' for 12-hour clock and 'H:i' for 24-hour clock)
         clickOpens:false,
         onClose: function (selectedDates, dateStr, instance) {
            this.FromDate = dateStr;
         }
      });
      this.DOIcalendarIcon.addEventListener('click', () => {
         this.DOIPicker._flatpickr.open();
      });
      flatpickr(this.ExpiryPicker, {
         closeOnSelect: true,
         dateFormat: 'd-m-Y', // the date format (default is 'Y-m-d')
         minDate: null, // the minimum selectable date in the calendar (default is null, which means no limit)
         maxDate:null, // the maximum selectable date in the calendar (default is null, which means no limit)
         defaultDate: this.Expirydate, // the initial date that should be shown in the calendar (default is null, which means today's date)
         enableTime: false, // whether to allow selecting time in addition to date
         enableSeconds: false, // whether to allow selecting seconds in addition to time
         time_24hr: false, // whether to use a 24-hour clock for time input
         minuteIncrement: 1, // the increment for minutes in the time input
         hourIncrement: 1, // the increment for hours in the time input
         timeFormat: 'h:i K', // the format for the time input (default is 'h:i K' for 12-hour clock and 'H:i' for 24-hour clock)
         clickOpens:false,
         onClose: function (selectedDates, dateStr, instance) { // a function that will be called when the calendar is closed
            this.FromDate = dateStr;
         }
      });
      this.ExpirycalendarIcon.addEventListener('click', () => {
         this.ExpiryPicker._flatpickr.open();
      });
      const notificationFreq = await this.profileService.GetNotificationFrequenciesDropdown();
      this.notificationFreqDropdown = notificationFreq;
      this.notificationFreqDropdown = Object.entries(notificationFreq).map(([key, value]) => ({
         value,
         key
      }));
      this.notificationFreqDropdown.reverse();
      const documentTypes = await this.profileService.GetDocumentTypesDropdown();
      this.documentTypesDropdown = documentTypes;
      this.documentTypesDropdown = Object.entries(documentTypes).map(([key, value]) => ({
         value,
         key
      }));
      const country = await this.profileService.GetCountryDropdown();
      this.countryDropdown = country;
      const notificationTypes = await this.profileService.GetNotificationTypesDropdown();
      this.notificationTypesDropdown = notificationTypes;
      this.notificationTypesDropdown = Object.entries(notificationTypes).map(([key, value]) => ({
         value,
         key
      }));
      this.selectedDocType = this.documentTypesDropdown.find(
         (document) => {
            document.key === this.DocumentType
         }
      );
      this.selectedCountry = this.countryDropdown.find(
         (country) => {
            country.CountryID === this.CountryID
         }
      );
      this.selectedState = this.statesDropdown.find(
         (state) => {
            state.StateID === this.StateID
         }
      );
      this.selectedNotifyFreq = this.notificationFreqDropdown.find(
         (notifreq) => {
            notifreq.key === this.NotificationFrequencyType
         }
      );
      this.selectedNotifyTypes = this.notificationTypesDropdown.find(
         (notitypes) => {
            notitypes.key === this.NotificationSubFrequencyType
         }
      );
      this.fileValidator();
      this.specializationInput.focus();
      this.handleFileSelect();
   }

   async selectCountry(data) {
      const states = await this.profileService.GetStatesDropdown();
      const Countryid = data.CountryID ? data.CountryID : data;
      this.statesDropdown = states.filter(option => option.CountryID === Countryid);
   }


   notify(checked) {
      this.notifyFields = checked;
   }

   toggleOtherFields(checked) {
      this.showOtherFields = checked;
   }

   async physicalCopy(checked) {
      const result = await this.dialogService.open({
         viewModel: Physicalcopypopup,
         model: "",
      }).whenClosed(response => response);
      if (result.wasCancelled) {
         return;
      }
      this.showPhysicalCopy = checked;
      this.expectedReturnDate();
   }

   expectedReturnDate() {
      flatpickr(this.returnPicker, {
         closeOnSelect: true,//
         dateFormat: 'd-m-Y', // the date format (default is 'Y-m-d')
         minDate: null, // the minimum selectable date in the calendar (default is null, which means no limit)
         maxDate:'today' , //new Date().toISOString().split("T")[0]
         defaultDate: this.returndate, // the initial date that should be shown in the calendar (default is null, which means today's date)
         enableTime: false, // whether to allow selecting time in addition to date
         enableSeconds: false, // whether to allow selecting seconds in addition to time
         time_24hr: false, // whether to use a 24-hour clock for time input
         minuteIncrement: 1, // the increment for minutes in the time input
         hourIncrement: 1, // the increment for hours in the time input
         timeFormat: 'h:i K', // the format for the time input (default is 'h:i K' for 12-hour clock and 'H:i' for 24-hour clock)
         clickOpens:false,
         onClose: function (selectedDates, dateStr, instance) {
            this.FromDate = dateStr;
         }
      });
      this.returncalendarIcon.addEventListener('click', () => {
         this.returnPicker._flatpickr.open();
      });
   }

   physicalnotify(checked) {
      this.physicalNotifyDays = checked;
   }

   returnDateSelected() {
      this.isDisabled = false;
   }

   fileValidator(){
    const fileInput = document.querySelector('input[type="file"]');
        const allowedTypes = ['image/jpeg', 'image/png','application/msword','application/pdf'];
        fileInput.addEventListener('change', (event) => {
            const file = event.target.files[0];
            const fileType = file.type;
            if (!allowedTypes.includes(fileType)) {
                this.fileMessage="Only JPEG, PNG, PDF and DOC files are allowed.";
                this.fileValidatorRule = true;
            } else {
                this.fileMessage=null;
                this.fileValidatorRule = false;
            }
        });
   }

openFileDialog() {
    document.getElementById('attachments').click();
}

handleFileSelect(files) {
  this.viewFile = false;
  const allowedFormats = ['.jpg', '.png', '.doc', '.pdf'];
  const uploadedFiles = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
    if (allowedFormats.includes(fileExtension)) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const dataURL = event.target.result;
        const fileObject = {
          EmployeeDocumentPathID: '',
          Path: dataURL,
          Name: file.name,
          LastUpdatedName: ''
        };
        // Add the file object to the uploadedFiles array
        uploadedFiles.push(fileObject);
      };
      reader.readAsDataURL(file);
    } else {
      // Handle invalid file format here (e.g., display an error message)
      this.uploadErrors.push({ error: { message: 'Invalid file format: ' + file.name } });
    }
  }
  // If you want to combine this.uploadedFiles with this.editFiles after uploading, do it after the loop
  uploadedFiles.push(...this.editFiles);
  this.editFiles = uploadedFiles;
}

get isFieldRequired() {
  return this.isSubmitClicked && this.uploadedFiles.length === 0;
}


removeFile(fileName) {
  this.dialogService.open({ viewModel: Confirmdelete, model: ""})
   .whenClosed(response =>{
    if (response.wasCancelled) {
        return;
     }else{
      const index = this.editFiles.findIndex(file => file.Name === fileName);
      if (index === -1) {  
        this.editFiles.splice(index, 1);
        const removeButtons = document.querySelectorAll(".remove-button");   
      removeButtons.forEach(function(button) {
        button.addEventListener("click", function() {
            var parentElement = button.parentNode;
            parentElement.parentNode.removeChild(parentElement);
        });
    });
      }
    }
   });
}

downloadFile(viewPath) {
  const downloadUrl = this.URL + viewPath;
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.target = '_blank';
  link.download = downloadUrl;
  link.click();
}
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }


   EditEmployeeDocument() {
      const refreshEvent = new CustomEvent('EditRefreshData');
      //this.errors = null;
      const docTypeKey = this.selectedDocType ? this.selectedDocType.key : this.DocumentType;
      const countryid = this.selectedCountry ? this.selectedCountry.CountryID : this.CountryID;
      const stateid = this.selectedState ? this.selectedState.StateID : this.StateID;
      const notifyFreq = this.selectedNotifyFreq ? this.selectedNotifyFreq.key : this.NotificationFrequencyType;
      const notifyTypes = this.selectedNotifyTypes ? this.selectedNotifyTypes.key : this.NotificationSubFrequencyType;
      if (this.editFiles.length === 0) {
        this.uploadErrors = [{ error: { message: 'Upload document is required.' } }];
      }else{
        this.uploadErrors = [{ error: { message: '' } }];
      //}
      this.controllerFactory.validate()
         .then(result => {
            if (result.valid) {
              const Editdata = {
                EmployeeID: this.empID,
                EmployeeDocumentID: this.EmployeeDocumentID,
                Paths: this.editFiles,
                DocumentName: '',
                DocumentType: docTypeKey,
                ExpiryDate: this.DocExpiryDate,
                NotifyExpiryDtIND: this.NotifyExpiryDtIND,
                NotifyDays: this.NotifyDays,
                DocIssuedDate: this.DocIssuedDate,
                DocExpiryDate: this.DocExpiryDate,
                CountryID: countryid,
                StateID: stateid,
                IssuingAuthority: this.IssuingAuthority,
                NotifyEmpInd: this.NotifyEmpInd,
                NotifyHRInd: this.NotifyHRInd,
                NotifyRMInd: this.NotifyRMInd,
                NotifyOtherInd: this.NotifyOtherInd,
                NotifyOtherEmailIDs: this.NotifyOtherEmailIDs,
                PhysicalCopyIND: this.PhysicalCopyIND,
                PhysicalCopyComments: this.PhysicalCopyComments,
                NotificationFrequencyType: notifyFreq,
                NotificationSubFrequencyType: notifyTypes,
                RetunNotifiIND: this.RetunNotifiIND,
                ReturnNotifiDays: this.ReturnNotifiDays,
                ExpectedReturnDate: this.ExpectedReturnDate,
                Comments: this.Comments,
                Subject: this.Subject,
                //Body : this.selectedDocType
             }
             this.profileService.SaveDocument(Editdata).then(() => {
              this.controller.cancel();
                this.profileService.GetEmployeeDocuments(this.empID).then(() => {
                   document.dispatchEvent(refreshEvent);
                });
             });
            }else{
              console.log(this.errors);
            }
          });

        }
   }

   cancel() {
    //window.location.reload();
    window.location.reload();
      this.controller.cancel();
   }
}
