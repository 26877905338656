import {inject,bindingMode,bindable} from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { Physicalcopypopup } from '../physicalcopypopup/physicalcopypopup';
import {DialogService} from 'aurelia-dialog';
import { ProfileService } from '../../../../shared/services/profielservice';
import { JwtService } from '../../../../shared/services/jwtservice';
import 'flatpickr/dist/flatpickr.min.css';
import flatpickr from 'flatpickr';
import {ValidationControllerFactory, ValidationRules,validateTrigger} from 'aurelia-validation';
//import Scrollbar from 'smooth-scrollbar';
import {LoginService} from "../../../../shared/services/loginservice";
import { Router } from 'aurelia-router';

@inject(DialogController, DialogService, ProfileService, JwtService, ValidationControllerFactory,Router,LoginService)
export class Adddocument {

   @bindable({
      defaultBindingMode: bindingMode.twoWay
   }) DOIdate;
   @bindable({
      defaultBindingMode: bindingMode.twoWay
   }) Expirydate;
   @bindable({
      defaultBindingMode: bindingMode.twoWay
   }) returndate;

   notifyFields = false;
   showOtherFields = false;
   showPhysicalCopy = false;
   physicalNotifyDays = false;
   isDisabled = true;
   documentTypesDropdown = [];
   countryDropdown = [];
   file;
   fileValidatorRule = false;
   selectedDocType = null; //aurelia select
   selectedCountry = null;
   selectedState = null;
   selectedNotifyFreq = null;
   selectedNotifyTypes = null;
   uploadedFiles = [];
   isSubmitClicked = false;
    isDragging = false;
    dialog;
    static inject = [Element];

   constructor(DialogController, dialogService, ProfileService, JwtService, controllerFactory,router,loginService) {
      this.controller = DialogController;
      this.dialogService = dialogService;
      this.profileService = ProfileService;
      this.jwtService = JwtService;
      this.controllerFactory = controllerFactory.createForCurrentScope();
      this.router = router;
       this.loginService = loginService;
      this.empID = this.jwtService.getEmployeeId();

      ValidationRules.customRule(
         'validEmails',
         (value, obj) => {
            if (typeof value !== 'string') {
               return false;
            }
            const emails = value.split(',');

            const emailRegex = /^[^\s@]+@[^\s@]+\.(com|co|in|org|cc|net)$/;
            const invalidEmails = [];
            for (const email of emails) {
               const trimmedEmail = email.trim();
               if (!emailRegex.test(trimmedEmail)) {
                  invalidEmails.push(trimmedEmail);
               }
            }
            // Check if any emails are invalid
            if (invalidEmails.length > 0) {
               obj.emailsErrors = ['Invalid email address(es)'];
               return false;
            }
            // Check if emails are separated by commas
            if (emails.length > 1 && value.indexOf(',') === -1) {
               obj.emailsErrors = ['Email(s) should be separated by commas'];
               return false;
            }
            obj.emailsErrors = [];
            return true;
         },
         '${$displayName} is invalid.',
         (obj) => ({
            obj
         })
      );

      ValidationRules.customRule(
        'file',
        () =>{
        if(this.fileValidatorRule === true){
          return false;
        }else{
          return true;
        }
      },
      //'${$displayName} is required'
      );

      ValidationRules
         .ensure('selectedDocType').required().withMessage('Document type is required')
         .ensure('Subject').required().withMessage('Document name is required')
         .ensure('DocIssuedDate').required().withMessage('Date of issuance is required')
         .ensure('NotifyDays').required().withMessage('Notify before expiry day is required.')
         .ensure('upload').required().withMessage('Upload document is required.')
         .satisfiesRule('file')
         .ensure('NotifyOtherEmailIDs').required().withMessage('Email id(s) field is required').then()
         .satisfiesRule('validEmails').withMessage("Invalid email address and Email(s) should be separated by commas")
         .ensure('NotifyEmpInd')
            .displayName('Employee')
            .satisfies(() => {
              return this.NotifyEmpInd || this.NotifyHRInd || this.NotifyRMInd || this.NotifyOtherInd;
            })
            .withMessage('Please select at least one notified.')
         .on(this);

   }

   get notifyCheckboxes() {
    return [
      this.NotifyEmpInd,
      this.NotifyHRInd,
      this.NotifyRMInd,
      this.NotifyOtherInd,
    ];
  }

   async bind() {
      setTimeout(()=>{
        Scrollbar.init(document.querySelector('.adddocument-scrollbar'));
      },100)
   }

   async attached() {
    this.specializationInput.focus();

      flatpickr(this.DOIPicker, {
         closeOnSelect: true,//new Date().toISOString().split("T")[0]
         dateFormat: 'd-m-Y', // the date format (default is 'Y-m-d')
         minDate: null, // the minimum selectable date in the calendar (default is null, which means no limit)
         maxDate: 'today', // the maximum selectable date in the calendar (default is null, which means no limit)
         defaultDate: this.DOIdate, // the initial date that should be shown in the calendar (default is null, which means today's date)
         enableTime: false, // whether to allow selecting time in addition to date
         enableSeconds: false, // whether to allow selecting seconds in addition to time
         time_24hr: false, // whether to use a 24-hour clock for time input
         minuteIncrement: 1, // the increment for minutes in the time input
         hourIncrement: 1, // the increment for hours in the time input
         timeFormat: 'h:i K', // the format for the time input (default is 'h:i K' for 12-hour clock and 'H:i' for 24-hour clock)
         clickOpens:false,
         onClose: function (selectedDates, dateStr, instance) {
            this.FromDate = dateStr;
         }
      });
      this.DOIcalendarIcon.addEventListener('click', () => {
         this.DOIPicker._flatpickr.open();
      });
      flatpickr(this.ExpiryPicker, {
         closeOnSelect: true,
         dateFormat: 'd-m-Y', // the date format (default is 'Y-m-d')
         minDate: 'today', // the minimum selectable date in the calendar (default is null, which means no limit)
         maxDate: null, // the maximum selectable date in the calendar (default is null, which means no limit)
         defaultDate: this.Expirydate, // the initial date that should be shown in the calendar (default is null, which means today's date)
         enableTime: false, // whether to allow selecting time in addition to date
         enableSeconds: false, // whether to allow selecting seconds in addition to time
         time_24hr: false, // whether to use a 24-hour clock for time input
         minuteIncrement: 1, // the increment for minutes in the time input
         hourIncrement: 1, // the increment for hours in the time input
         timeFormat: 'h:i K', // the format for the time input (default is 'h:i K' for 12-hour clock and 'H:i' for 24-hour clock)
         clickOpens:false,
         onClose: function (selectedDates, dateStr, instance) { // a function that will be called when the calendar is closed
            this.FromDate = dateStr;
         }
      });
      this.ExpirycalendarIcon.addEventListener('click', () => {
         this.ExpiryPicker._flatpickr.open();
      });
      const notificationFreq = await this.profileService.GetNotificationFrequenciesDropdown();
      this.notificationFreqDropdown = notificationFreq;
      this.notificationFreqDropdown = Object.entries(notificationFreq).map(([key, value]) => ({
         value,
         key
      }));
      this.notificationFreqDropdown.reverse();
      const documentTypes = await this.profileService.GetDocumentTypesDropdown();
      this.documentTypesDropdown = documentTypes;
      this.documentTypesDropdown = Object.entries(documentTypes).map(([key, value]) => ({
         value,
         key
      }));
      const country = await this.profileService.GetCountryDropdown();
      this.countryDropdown = country;
      const notificationTypes = await this.profileService.GetNotificationTypesDropdown();
      this.notificationTypesDropdown = notificationTypes;
      this.notificationTypesDropdown = Object.entries(notificationTypes).map(([key, value]) => ({
         value,
         key
      }));

      this.fileValidator();
   }

   async selectCountry(data) {
      const states = await this.profileService.GetStatesDropdown();
      this.statesDropdown = states.filter(option => option.CountryID === data.CountryID);
   }

   notify(checked) {
      this.notifyFields = checked;
   }

   toggleOtherFields(checked) {
      this.showOtherFields = checked;
   }

   async physicalCopy(checked) {
      const result = await this.dialogService.open({
         viewModel: Physicalcopypopup,
         model: "",
      }).whenClosed(response => response);
      if (result.wasCancelled) {
         return;
      }
      this.showPhysicalCopy = checked;
      flatpickr(this.returnPicker, {
         closeOnSelect: true,
         dateFormat: 'd-m-Y', // the date format (default is 'Y-m-d')
         minDate: 'today', // the minimum selectable date in the calendar (default is null, which means no limit)
         maxDate: null, // the maximum selectable date in the calendar (default is null, which means no limit)
         defaultDate: this.returndate, // the initial date that should be shown in the calendar (default is null, which means today's date)
         enableTime: false, // whether to allow selecting time in addition to date
         enableSeconds: false, // whether to allow selecting seconds in addition to time
         time_24hr: false, // whether to use a 24-hour clock for time input
         minuteIncrement: 1, // the increment for minutes in the time input
         hourIncrement: 1, // the increment for hours in the time input
         timeFormat: 'h:i K', // the format for the time input (default is 'h:i K' for 12-hour clock and 'H:i' for 24-hour clock)
         clickOpens:false,
         onClose: function (selectedDates, dateStr, instance) {
            this.FromDate = dateStr;
         }
      });
      this.returncalendarIcon.addEventListener('click', () => {
         this.returnPicker._flatpickr.open();
      });
   }

   physicalnotify(checked) {
      this.physicalNotifyDays = checked;
   }

   returnDateSelected() {
      this.isDisabled = false;
   }

   fileValidator(){
    const fileInput = document.querySelector('input[type="file"]');
        const allowedTypes = ['image/jpeg', 'image/png','application/msword','application/pdf'];
        fileInput.addEventListener('change', (event) => {
            const file = event.target.files[0];
            const fileType = file.type;
            if (!allowedTypes.includes(fileType)) {
                this.fileMessage="Only JPEG, PNG, PDF and DOC files are allowed.";
                this.fileValidatorRule = true;
            } else {
                this.fileMessage=null;
                this.fileValidatorRule = false;
            }
        });
   }

   openFileDialog() {
    document.getElementById('attachments').click();
   }

  handleFileSelect(files) {
    this.fileError = '';
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const allowedFormats = ['.jpg', '.png', '.doc', '.pdf'];
      const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
  
      if (allowedFormats.includes(fileExtension)) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const dataURL = event.target.result;
          const fileObject = {
            Name: file.name,
            Path: dataURL
          };
          // Push the file object to the uploadedFiles array
          this.uploadedFiles.push(fileObject);
        };
        reader.readAsDataURL(file);
      } else {
        this.uploadErrors = [{ error: { message: '' } }];
        
      }
    }
  }

  get isFieldRequired() {
    return this.isSubmitClicked && this.uploadedFiles.length === 0;
  }
  

  removeFile(file) {
    const index = this.uploadedFiles.findIndex((uploadedFile) => uploadedFile.Name === file.Name);
    if (index > -1) {
      this.uploadedFiles.splice(index, 1);
    }
    if (this.uploadedFiles.length === 0) {
      this.uploadErrors = [{ error: { message: 'Please upload the document.' } }];
    }else{
      this.uploadErrors = [{ error: { message: '' } }];
    }
  }
  

   handleKeyDown(event) {
    if (event.key === 'Enter') {
          event.preventDefault();
          event.stopPropagation();
          return false;
      }
      return true;
  }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }

    saveEmployeeDocument() {
      this.errors = null;
      event.preventDefault();
      this.isSubmitClicked = true;
      const refreshEvent = new CustomEvent('refreshData');
      const countryid = this.selectedCountry ? this.selectedCountry.CountryID : null;
      const stateid = this.selectedState ? this.selectedState.StateID : null;
      const notifyFreq = this.selectedNotifyFreq ? this.selectedNotifyFreq.key : null;
      const notifyTypes = this.selectedNotifyTypes ? this.selectedNotifyTypes.key : null;
      const expectedReturnDate = this.ExpectedReturnDate ? this.ExpectedReturnDate : null;
      this.controllerFactory.validate()
         .then(result => {
            if (result.valid) {
              if (this.uploadedFiles.length === 0) {
                this.fileError = 'Upload document is required.';
                const errorMessages = document.querySelectorAll(".adddocument-scrollbar");
                if (errorMessages.length > 0) {
                  errorMessages[0].scrollIntoView({ behavior: "smooth" });
                }
                return false;
              }
               const documentData = {
                  EmployeeID: this.empID, //done
                  Paths: this.uploadedFiles,
                  DocumentName: '', //done
                  DocumentType: this.selectedDocType.key, //done
                  ExpiryDate: this.DocExpiryDate, //done
                  NotifyExpiryDtIND: this.NotifyExpiryDtIND, //done
                  NotifyDays: this.NotifyDays, //done
                  DocIssuedDate: this.DocIssuedDate,
                  DocExpiryDate: this.DocExpiryDate, //done
                  CountryID: countryid, //done
                  StateID: stateid, //done
                  IssuingAuthority: this.IssuingAuthority, //done
                  NotifyEmpInd: this.NotifyEmpInd, //done
                  NotifyHRInd: this.NotifyHRInd, //done
                  NotifyRMInd: this.NotifyRMInd, //done
                  NotifyOtherInd: this.NotifyOtherInd, //done
                  NotifyOtherEmailIDs: this.NotifyOtherEmailIDs, //done
                  PhysicalCopyIND: this.PhysicalCopyIND,
                  PhysicalCopyComments: this.PhysicalCopyComments, //done
                  NotificationFrequencyType: notifyFreq, //done
                  NotificationSubFrequencyType: notifyTypes, //done
                  RetunNotifiIND: this.RetunNotifiIND, //done
                  // ExpectedReturnDate: this.ExpectedReturnDate,
                  ExpectedReturnDate: expectedReturnDate, //done
                  ReturnNotifiDays: this.ReturnNotifiDays, //done
                  CustodianReturnDate: expectedReturnDate,
                  Comments: this.Comments, //done
                  Subject: this.Subject, //done
                  TypeID: this.selectedDocType.key, //done
                  EmployeeDocumentID:this.EmployeeDocumentID,
                  //Body : this.selectedDocType
               };
               const DocumentValidatecheck={
                  EmployeeID: this.empID,
                  Subject: this.Subject, 
                  EmployeeDocumentID:this.EmployeeDocumentID,
              };
               this.profileService.DocumentAvoidDuplicate(DocumentValidatecheck).then(documentName => {
                  if(documentName === 1)
                  {
                  document.getElementById('docNameError').classList.add("doc-Error");
                  const errorMessages = document.querySelectorAll(".doc-Error");
                  if (errorMessages.length > 0) {
                  errorMessages[0].scrollIntoView({ behavior: "smooth" });
                  }
                  this.errorMessage=true;
                  this.errorMessage = 'Document name already exists.';

                  //setTimeout(()=>{this.errorMessage=null},5000);
                  }
               else{
               this.profileService.SaveDocument(documentData).then((response) => {
                  this.controller.cancel();
                  this.profileService.GetEmployeeDocuments(this.empID)
                     .then(() => {
                        document.dispatchEvent(refreshEvent); // Trigger the custom event
                     });
               });
            }
         });
      }else{
        const errorMessages = document.querySelectorAll(".adddocument-scrollbar");
        if (errorMessages.length > 0) {
          errorMessages[0].scrollIntoView({ behavior: "smooth" });
        }
      }
   }); 
   }

   cancel() {
      this.controller.cancel();
   }
}
