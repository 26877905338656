import {inject } from 'aurelia-framework';
import { ProfileService } from '../../../shared/services/profielservice';
import { JwtService } from '../../../shared/services/jwtservice';
import {DialogService} from 'aurelia-dialog';
import { Adddocument } from './adddocument/adddocument';
import { Editdocument } from './editdocument/editdocument';
import { Viewdocument } from './viewdocument/viewdocument';
import { AlertpopupComponent } from '../../alertpopup/alertpopupcomponent';
import Scrollbar from 'smooth-scrollbar';
import { Router } from 'aurelia-router';
import {config} from '../../../shared/services/config';
import {LoginService} from "../../../shared/services/loginservice";

@inject(ProfileService, JwtService, DialogService,Router,LoginService)
export class Documents {

   pageSize = config.grid_PazeSize;
   DeleteDeletmessage = false;
   DocumentSuccessmessage = false;

   constructor(ProfileService, JwtService, DialogService,router,loginService) {
      this.profileService = ProfileService;
      this.jwtService = JwtService;
      this.dialogService = DialogService;
      this.router = router;
       this.loginService = loginService;
   }

   activate() {
      this.EmployeeDocuments = this.RefreshEditDocument();
      this.EmployeeDocuments = this.RefreshEditDocument();
   }

   async bind() {
      setTimeout(()=>{
        Scrollbar.init(document.querySelector('.document-scrollbar'));
      },100)
   }

   async attached(params) {

      this.empID = this.jwtService.getEmployeeId();
      const id = this.router.currentInstruction.queryParams.id;
            if(id != null && id !='' && id !=undefined)
            {
                this.empID=id;
            }
            else{

                this.empID=this.jwtService.getEmployeeId();
            
            }

      document.addEventListener('EditRefreshData', this.RefreshEditDocument);
      document.addEventListener('refreshData', this.RefreshDocument);
      const employeeDocuments = await this.profileService.GetEmployeeDocuments(this.empID);
      this.EmployeeDocuments = employeeDocuments;
   }

   addDocument() {
      this.dialogService.open({
         viewModel: Adddocument
      })
   }

   RefreshDocument = () => {
      this.profileService.GetEmployeeDocuments(this.empID).then(data => {
         this.EmployeeDocuments = data;
         this.Docmessage = "Document added successfully";
         this.DocumentSuccessmessage = true;
         this.DocumentUpdatemessage = false;
         setTimeout(() => {
            this.Docmessage = null
         }, 3000);
      })
   }

   editDocument(editData) {
      this.dialogService.open({
         viewModel: Editdocument,
         model: editData
      });
   }

   RefreshEditDocument = () => {
      // Call the API or refresh the data in GridComponent
      this.profileService.GetEmployeeDocuments(this.empID).then(data => {
         this.EmployeeDocuments = data
         this.Docmessage = "Document updated successfully";
         this.DocumentSuccessmessage = true;
         this.DocumentUpdatemessage = false;
         setTimeout(() => {
            this.Docmessage = null
         }, 3000);
      })
   }

   viewDocument(viewData) {
      this.dialogService.open({
         viewModel: Viewdocument,
         model: viewData
      });
   }

   async deleteDocument(data) {
      const DocID = data.EmployeeDocumentID;
      const result = await this.dialogService.open({
         viewModel: AlertpopupComponent,
         model: "",
      }).whenClosed(response => response);
      if (result.wasCancelled) {
         return;
      }
      await this.profileService.DeleteEmployeeDocument(DocID, this.empID);
      const employeeDocuments = await this.profileService.GetEmployeeDocuments(this.empID);
      this.docDeletmessage = " Document deleted successfully";
      this.DeleteDeletmessage = true;
      setTimeout(() => {
         this.docDeletmessage = null
      }, 3000);
      this.EmployeeDocuments = employeeDocuments;
   }

}
